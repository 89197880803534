import React from 'react';

import * as styles from '../../../styles/flex/home/homeTitle.module.css';

function HomeTitle() {
    return (
        <section className={styles.HomeTitle}>
            <h2>GTA<span> Max Profit</span></h2>
            <div className={styles.Curve}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className={styles.ShapeFill}></path>
                </svg>
            </div>
        </section>
    );
}

export default HomeTitle;