import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../../../styles/flex/home/calculatorCards.module.css';

function BunkerCard() {
    return (
        <section className={styles.CalculatorCard}>
            <div className={styles.Container}>
                <div className={styles.BunkerLeft}></div>
                <div className={styles.Right}>
                    <div className={styles.Content}>
                        <h2>Gunrunning</h2>
                        <p>Gunrunning brought new Bunker HQs from where players can launch illicit weapon trading missions and research new weapon attachments and upgrades.</p>
                        <Link to="/profit-calculators/gta-online-bunker-profit-calculator" className={styles.LinkButton}>Bunker</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BunkerCard;
