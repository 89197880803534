import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../../../styles/flex/home/calculatorCards.module.css';

function BikersCard() {
    return (
        <section className={styles.CalculatorCard}>
            <div className={styles.Container}>
                <div className={styles.BikersLeft}></div>
                <div className={styles.Right}>
                    <div className={styles.Content}>
                        <h2>Bikers</h2>
                        <p>Bikers brought the eagerly-anticipated ability to form and lead your very own Motorcycle Club to GTAO.</p>
                        <Link to="/profit-calculators/gta-online-biker-businesses" className={styles.LinkButton}>MC Business</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BikersCard;