import * as React from "react";
import AdSense from 'react-adsense';

import Layout from "../components/layout"
import Seo from "../components/seo"

import HomeTitle from '../components/flex/home/homeTitle';
import CalculatorSelect from '../components/flex/home/calculatorSelect';

import 'bootstrap/dist/css/bootstrap.min.css';
import * as styles from '../styles/index.module.css';
import * as ad from '../styles/ads.module.css';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className={styles.Container}>
      <HomeTitle/>
      <div className={ad.Container} id="ad">
        {/* auto full width responsive ads */}
        <AdSense.Google
          client='ca-pub-5234651621117566'
          slot='8655171133'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
        />
      </div>
      <CalculatorSelect/>
    </div>
  </Layout>
)

export default IndexPage;