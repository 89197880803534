import React from 'react';
import Slide from 'react-reveal/Slide';

import BunkerCard from './bunkerCard';
import BikersCard from './bikersCard';
// import NightclubCard from '../components/nightclubCard';
// import ArcadeCard from '../components/arcadeCard';

import * as styles from '../../../styles/flex/home/calculatorSelect.module.css';

function calculatorSelect() {
    const calculatorCards = [
        // {
        //     title: 'Diamond Casino',
        //     card: <ArcadeCard/>
        // },
        // {
        //     title: 'After Hours',
        //     card: <NightclubCard/>
        // },
        {
            'title': 'Gunrunning',
            'card': <BunkerCard/>
        },
        {
            'title': 'Bikers',
            'card': <BikersCard/>
        }
    ];

    return (
        <section className={styles.CalculatorSelect}>
            <h2>Choose Your GTAO Business</h2>
            <p>Make the most from your money in GTAO</p>
            <div>
                {calculatorCards.map(function(cards, index) {
                    return (
                        <Slide up key={index}>
                            <div className={styles.Calculators}>
                                {cards.card}
                            </div>
                        </Slide>
                    )
                })}
            </div>
        </section>
    )
}

export default calculatorSelect;
